import React from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { timeFilters, getStartDate, cleanCraftDate } from "./../modules/time";
import { eventTypeList } from "./../modules/eventTypes";
import Layout from "./../components/Layout";
import ProgrammeHero from "./../components/ProgrammeHero";
import RecommendationBlock from "./../components/RecommendationBlock";
import ProgrammeBody from "./../components/ProgrammeBody";
import EventsSectionFilter from "../components/EventsSectionFilter";
import EventGrid, { EmptyEventGrid } from "./../components/EventGrid";
import HomeHero from "./../components/HomeHero";

const allTimes = () => true;

const EventsPage = () => {
  const eventsQuery = useStaticQuery(graphql`
    query {
      eventsFrontPage: craftEventsFrontEventsFrontEntry {
        title
        seoTitle
        seoDescription
        eventFrontPageBanner {
          ... on Craft_event_event_Entry {
            bannerCallToAction
            bannerColor
            bannerGraphic {
              ... on Craft_eventsAssets_Asset {
                wideImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 1440) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                wideImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 1440) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_communityAssets_Asset {
                wideImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 1440) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            bannerGraphicPortrait {
              ... on Craft_eventsAssets_Asset {
                tallImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 800) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                tallImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 800) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_communityAssets_Asset {
                tallImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 800) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            bannerTopline
            bannerTitle
            bannerSubtitleLine2
            bannerSubtitleLine1
          }
          slug
          typeHandle
        }
        eventFrontPageRecommendationBlock {
          ... on Craft_recommendation_recommendation_Entry {
            recommendationName {
              title
              slug
            }
            recommendationEvents {
              ... on Craft_event_event_Entry {
                id
                title
                eventEndDateTime
                eventStartDateTime
                eventShortDescription
                slug
                eventType
                headerImage {
                  ... on Craft_editorialAssets_Asset {
                    cardImage: localFile {
                      publicURL
                      childImageSharp {
                        fixed(width: 290) {
                          src
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        eventFrontPageProgramme {
          ... on Craft_programmes_programmes_Entry {
            id
            title
            programmeBackgroundGraphic {
              ... on Craft_eventsAssets_Asset {
                wideImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(fit: COVER, width: 1440, height: 610) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            programmeHeadline
            programmeLogoGraphic {
              ... on Craft_eventsAssets_Asset {
                wideImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(fit: CONTAIN, width: 1440, height: 610) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            topline
            slug
          }
        }
      }
      events: allCraftEventEventEntry(
        sort: {fields: eventStartDateTime}
        filter: {enabled: {eq: true}}
        ) {
        nodes {
          id
          title
          isStaging
          eventEndDateTime
          eventStartDateTime
          eventShortDescription
          slug
          eventType
          listingImage {
            url
            ... on Craft_communityAssets_Asset {
              card: localFile {
                publicURL
                childImageSharp {
                   fixed(height: 190, width: 190, fit: COVER) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          eventLocation {
            title
            slug
            typeHandle
          }
          headerImage {
            url
            ... on Craft_editorialAssets_Asset {
              card: localFile {
                publicURL
                childImageSharp {
                  fixed(height: 190, width: 190, fit: COVER) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
      global:craftGlobalFilterOptionsGlobalSet {
        eventFilterSetting {
          label
          id
          enableDisable
        }
      }
    }
  `);
    

  // console.log(eventsQuery.eventsFrontPage);
  const thisBanner = eventsQuery.eventsFrontPage.eventFrontPageBanner;
  const seoDescriptionEvent = eventsQuery.eventsFrontPage.seoDescription?eventsQuery.eventsFrontPage.seoDescription:null;
 const seoTitleEvent =  eventsQuery.eventsFrontPage.seoTitle?eventsQuery.eventsFrontPage.seoTitle:null;
 const globalFilter = eventsQuery.global.eventFilterSetting;


  // const everything = eventsQuery.events.nodes;
  const now = new Date();
  const everything = eventsQuery.events.nodes.filter((x) => {
    const thisDate = new Date(x.eventEndDateTime || x.eventStartDateTime);
    return thisDate >= now;
  });
  // console.log(eventsQuery.events.nodes, everything);
  const thisProgramme = eventsQuery.eventsFrontPage.eventFrontPageProgramme
    .length
    ? eventsQuery.eventsFrontPage.eventFrontPageProgramme[0]
    : null;
  const thisRecommendation = eventsQuery.eventsFrontPage
    .eventFrontPageRecommendationBlock.length
    ? eventsQuery.eventsFrontPage.eventFrontPageRecommendationBlock[0]
    : null;
  // console.log(thisRecommendation);
  const [shownEvents /*setShownEvents*/] = React.useState(everything); // Do we need this?
  

  //let eventL=eventTypeList.map((x) => x.id)
  let eventlG=globalFilter.map(function(filterData,index) { if(filterData.enableDisable=='1'){ return filterData.id} });
  
  const [currentTypes, setCurrentTypes] = React.useState(
    eventlG
  );

  const [currentTimeFilter, setCurrentTimeFilter] = React.useState(
    () => allTimes
  );
  const [currentTimeFilterName, setCurrentTimeFilterName] =
    React.useState(null);
  const [startDate, setStartDate] = React.useState(getStartDate("today")); // this is a real date

  const checkTypes = (typesArray) => {
    for (let i = 0; i < typesArray.length; i++) {
      if (currentTypes.indexOf(typesArray[i]) > -1) {
        return true;
      }
      
    }
    // console.log(typesArray);
    return false;
  };
  
  let typeMaster=[];
  let choosenFilters=globalFilter.map(function(filterData,index) { if(filterData.enableDisable=='1'){ return filterData.id} });
  choosenFilters=choosenFilters.filter(element => {
                      return element !== undefined;
                    });
  let finalFilter=[];

  {shownEvents.map(function(ev, i){
    ev.eventType.map(function(e,j){
      if(choosenFilters.includes(e) && !typeMaster.includes(e))
      {
        typeMaster.push(e);
      }
    })
  })}
  

    {globalFilter.map(function(ty,i){
        if(typeMaster.includes(ty.id))
        {
          let m={id:ty.id,name:ty.label}
          finalFilter.push(m);
        }
    })}

  // TODO: we need to have a block we can show if nothing has been touched which is just today's events

  // console.log(everything, currentTimeFilter);
  // console.log(currentTimeFilterName);

  // console.log(`\n\n${startDate}: ${typeof startDate}\n\n`);
  // console.log(currentTimeFilterName);
  return (
    <Layout
      backgroundColor={"var(--white)"}
      footerColor={"var(--red)"}
      skipHeader
      title={seoTitleEvent}
      SEODescription={seoDescriptionEvent}
    >
      {thisBanner ? (
        <HomeHero
          heros={thisBanner}
          onCallToAction={(e) => {
            navigate(e);
          }}
        />
      ) : null}
      <ProgrammeBody noBottom eventsPage>
        {/* set z-index =2 on this and extend the white border */}
        <EventsSectionFilter
          selected={(e) => {
            setCurrentTypes(e);
            if (!e.length) {
              // s("nothing selected!");
              setCurrentTimeFilter(() => allTimes);
            }
          }}
          choosenFilters={finalFilter}
          timeSelected={(e, customDate) => {
            if (e === "thisDate") {
              // console.log("custom date", customDate);
              const customDateFilter = (start, end) => {
                const startDate = new Date(start);
                const endDate = new Date(end || start);
                const startTomorrow = new Date(
                  customDate.getFullYear(),
                  customDate.getMonth(),
                  customDate.getDate() + 1
                );
                return startDate <= startTomorrow && endDate >= customDate;
              };
              setCurrentTimeFilter(() => customDateFilter);
              setCurrentTimeFilterName("customDateFilter");
              setStartDate(() => customDate);
            } else {
              setCurrentTimeFilter(() => timeFilters[e]);
              setCurrentTimeFilterName(e);
              setStartDate(() => getStartDate(e));
            }
          }}
        />
        {shownEvents
          .filter((x) =>
            currentTypes.length === 0 ? true : checkTypes(x.eventType)
          )
          .filter((x) =>
            currentTimeFilter(
              cleanCraftDate(x.eventStartDateTime),
              cleanCraftDate(x.eventEndDateTime || x.eventStartDateTime)
            )
          ).length ? (
          <ul>
            <EventGrid
              events={shownEvents
                .filter((x) =>
                  currentTypes.length === 0 ? true : checkTypes(x.eventType)
                )
                .filter((x) =>
                  currentTimeFilter(
                    cleanCraftDate(x.eventStartDateTime),
                    cleanCraftDate(x.eventEndDateTime || x.eventStartDateTime)
                  )
                )}
              firstDate={startDate}
              firstDateType={currentTimeFilterName}
            />
          </ul>
        ) : (
          <EmptyEventGrid>No events match your selections!</EmptyEventGrid>
        )}
        {thisRecommendation ? (
          <RecommendationBlock
            name={thisRecommendation.recommendationName[0]}
            events={thisRecommendation.recommendationEvents}
          />
        ) : null}
        {thisProgramme ? (
          <ProgrammeHero
            isBanner
            slug={thisProgramme.slug}
            topline={thisProgramme.topline}
            titleText={thisProgramme.title}
            subTitleText={thisProgramme.programmeHeadline}
            backgroundColor={"var(--red)"}
            color={"var(--white)"}
            titleColor={"var(--red)"}
            backgroundImage={
              thisProgramme.programmeBackgroundGraphic.length
                ? thisProgramme.programmeBackgroundGraphic[0]
                : null
            }
            titleImage={
              thisProgramme.programmeLogoGraphic.length
                ? thisProgramme.programmeLogoGraphic[0]
                : null
            }
          />
        ) : null}
      </ProgrammeBody>
    </Layout>
  );
};

export default EventsPage;
